export const BarStyles = {
  BARS: '0',
  CANDLES: '1',
  HOLLOW_CANDLES: '9',
  HEIKIN_ASHI: '8',
  LINE: '2',
  AREA: '3',
  RENKO: '4',
  LINE_BREAK: '7',
  KAGI: '5',
  POINT_AND_FIGURE: '6'
}

export const IntervalTypes = {
  D: 'D',
  W: 'W'
}

export const RangeTypes = {
  YTD: 'ytd',
  ALL: 'all'
}

export const Themes = {
  LIGHT: 'Light',
  DARK: 'Dark'
}

export const DisplayModes = {
  REGULAR: 'Regular',
  COMPACT: 'Compact',
  ADAPTIVE: 'Adaptive'
}
